@use '@angular/material' as mat;

@include mat.core();

@import "app/styles/common/globals";
@import "~@ng-select/ng-select/themes/default.theme.css";

// overwrite some package styles

body {
  font-size: $font-size-base;
}

ion-icon {
  vertical-align: text-bottom;
  pointer-events: none;
  font-size: 24px; // this can be overwritten by using size=small or size=large in the HTML
}

.form-control {
  font-size: $font-size-base;
  color: $font-color-input;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $font-color-input;
  opacity: 1; /* Firefox */
}


a {
  cursor: pointer;
  text-decoration: none;
}

button:focus {
  outline: none;
}

a, .clickable {
  cursor: pointer;
}

.container-xs-large {
  @media (max-width: $screen-xs-max) {
    max-width: none;
  }
}

.snackbar {
  background-color: #323232;
  color: #ffffff;
}

.spacer {
  flex: 1 1 auto;
}

.cdk-overlay-container:has(.owo-btn-tooltip) {
  z-index: 1050;
}
