//
// Variables
// --------------------------------------------------

$font-color-form: #4C4C4C;
$font-color-input: #333;
$form-border-color: #ced4da;
$color-text-secondary: #898989;

/*
 * Colors
 */
$color-shelby: #0066FF;
$color-shelbyfaded: #478AF1;
$color-blue-lighter: #F1F7FF;
$color-blue-light: #E7F0FD;
$color-pearl: #F4F4F4;
$color-raven: #333333;
$color-fade-light: #636D80;
$color-fade: #505867;
$color-ravenfaded: #4C4C4C;
$color-error: #cd3c37;
$color-errorfaded: #F0BAB8;
$color-ochre: #D68100;
$color-warning: #FDAF29;
$color-warningfaded: #FEDFA9;
$color-success: #008000;
$color-successfaded: #99CC99;

$color-contact-1: #60ab62;
$color-contact-2: #6cc2cb;

$color-logo-light: #51BBED;
$color-logo-dark: #26358A;

$gray-darker:            #263238;
$gray-dark:              #455A64;
$gray:                   #607D8B;
$gray-light:             #90A4AE;
$gray-lighter:           #ced4da;
$gray-lightest:          #F4F4F4;
$gray-disabled:          #E9ECEF;


/*
 * Color Palette
 */
$brand-primary: $color-shelby;
$brand-success: $color-shelby;
$brand-info:    $color-shelbyfaded;
$brand-warning: $color-warning;
$brand-danger:  $color-error;

/*
 * Status colors
 */
$status-neutral: #bababa;
$status-open: #588fb3;
$status-urgent: #f4c478;
$status-danger: #f47878;

/*
 * Iconography
 */
$icon-font-path: "/fonts/";
$icon-size: 2rem;

/*
 * Typography
 */
$title-color: $color-raven;


// Import Fonts
@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-Bold.ttf');
  font-weight: 700;
}

// Scaffolding & Typography
$text-color: #000;
$body-bg: darken(#fff, 2%);
$link-color: $color-shelbyfaded;
$link-hover-color: $color-shelby;
$link-hover-decoration: none;
$font-family-sans-serif: 'Roboto', sans-serif;
$font-size-base: 15px;
$font-family-base: $font-family-sans-serif;
$font-size-h1: 36px;
$font-size-h2: 30px;
$font-size-h3: 24px;
$font-size-h4: 20px;
//$line-height-base: 2.1; // ~34px;

/*
 * Forms
 */
$input-border: darken($color-pearl, 10%);
$input-border-focus: $color-shelby;
$input-border-radius: 3px;
$input-height-base: 50px;

/*
 * Buttons
 */
$btn-default-color: $color-shelby;
$btn-default-bg: #fff;
$btn-default-border: $color-shelby;
$btn-border-radius-base: 3px;

$btn-primary-color: $color-shelby;
$btn-primary-bg: #fff;
$btn-primary-border: $color-shelby;

/*
 * Navbar
 */
$navbar-height: 70px;
$navbar-margin-bottom: 0;
$navbar-border-radius: 0;
$navbar-border-color: #e7e7e7;
$navbar-default-bg: #fff;
$navbar-default-link-color: #767676;
$navbar-default-link-hover-color: $color-shelby;
$navbar-default-link-active-color: $color-shelby;
$navbar-default-link-active-bg: #fff;

/*
 * Card
 */
$card-border-color: #dbdada;

/*
 * Tables
 */
$table-bg: #fff;
$table-head: $color-blue-lighter;
$table-head-color: #CCCCCC;
$table-data-color: #7F7F7F;
$table-cell-padding: 18px;

/*
 * Grid system
 */
$grid-float-breakpoint: 992px;

/*
 * Modals
 */
$modal-backdrop-bg: $color-raven;

/*
 * Z-Index
 */
$zindex-navbar-popup: 1020;
$zindex-navbar-primary: 1010;
$zindex-navbar-secondary: 1000;
$zindex-sub-navbar: 1005;
$zindex-form_menu: 1000;

/*
 * Borders
 */
$border-gray-light: 1px solid $gray-light;
$border-gray-lighter: 1px solid $card-border-color;
$border-gray-lightest: 1px solid $gray-lightest;
$border-ravenfaded: 1px solid $color-ravenfaded;
$border-shelby: 1px solid $color-shelby;

/*
 * Checkboxes
 */
$checkbox-dimension: 1.5rem;
$checkbox-small-dimension: 1rem;

/*
 * Containers
 */
$border-radius-container: 0.5rem;

//== Media queries breakpoints

// Extra small screen / phone
$screen-xs-min:              480px;
// Small screen / tablet
$screen-sm-min:              768px;
// Medium screen / desktop
$screen-md-min:              992px;
// Large screen / wide desktop
$screen-lg-min:              1200px;
// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

